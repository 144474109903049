import React, { useContext, useState } from "react";
import { FloatingModeContext } from "@components/contexts/FloatingModeContext";
import Logo from "@components/common/Logo";
import { Link } from "gatsby";
import { FaBars, FaTimes } from "react-icons/fa";
import { navigate } from "gatsby-link";
import { MdShoppingCart } from "react-icons/md";
import { CartContext } from "@components/contexts/CartContext";
import "@styles/Header.css";

const Header = () => {
    const { floatingMode } = useContext(FloatingModeContext);
    const [mobileMenu, setMobileMenu] = useState(false);
    const { itemsCount } = useContext(CartContext);

    return (
        <>
            <div
                className={`z-999 header background shadow-md duration-300 ${
                    floatingMode ? "fixed top-0 w-full" : "relative"
                }`}
            >
                <div className={`shadow-md z-40 relative pt-3`}>
                    <div className="container">
                        <div
                            className={`duration-300 space-x-24 items-center justify-between flex ${
                                floatingMode ? "py-3 md:pb-6" : "py-8"
                            }`}
                        >
                            <Link to="/" title="Inicio" className="flex-shrink-0">
                                <Logo className="duration-300 w-24" />
                            </Link>

                            <div className="items-center lg:hidden">
                                {/** Botón mostrar esconder menú mobile */}
                                <button
                                    className={`nav-link-button`}
                                    aria-label="Mostrar/esconder menú"
                                    type="button"
                                    onClick={() => {
                                        setMobileMenu(!mobileMenu);
                                    }}
                                >
                                    {mobileMenu ? (
                                        <FaTimes className="h-6 w-6 fill-current" />
                                    ) : (
                                        <FaBars className="h-6 w-6 fill-current" />
                                    )}
                                </button>

                                {/** Botón Cesta */}
                                <button
                                    aria-label="Ir a la cesta"
                                    type="button"
                                    title={`Ir a la Cesta (${itemsCount})`}
                                    className={`ml-5 items-center nav-link-button`}
                                    onClick={() => {
                                        navigate("/cesta");
                                    }}
                                >
                                    <MdShoppingCart
                                        className={`h-6 w-6 fill-current ${
                                            itemsCount > 0 ? "text-ccyan" : ""
                                        } `}
                                    />
                                </button>
                            </div>

                            {/** Desktop menú */}
                            <nav className="hidden items-center lg:flex lg:gap-x-5 xl:gap-x-8 flex-wrap gap-y-2">
                                {process.env.GATSBY_BLOG_ENABLED === "true" && (
                                    <Link
                                        to="/blog"
                                        activeClassName={`nav-link active`}
                                        className={`nav-link`}
                                        partiallyActive={true}
                                    >
                                        Blog
                                    </Link>
                                )}

                                <Link
                                    to="/calculadora-iopos"
                                    activeClassName={`nav-link active`}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Calculadora
                                </Link>

                                <Link
                                    to="/convocatorias"
                                    activeClassName={`nav-link active`}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Convocatorias
                                </Link>

                                <Link
                                    to="/aplicaciones/"
                                    activeClassName={`nav-link active`}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Apps
                                </Link>

                                <Link
                                    to="/tienda/libros"
                                    activeClassName={`nav-link active `}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Libros
                                </Link>

                                {/*<Link
                                    to="/tienda/audios"
                                    activeClassName={`nav-link active `}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Podcasts
                                </Link>*/}

                                <Link
                                    to="/oposiciones"
                                    activeClassName={`nav-link active `}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Oposiciones
                                </Link>

                                {/*<Link
                                    to="/tienda/cursos-online"
                                    activeClassName={`nav-link active `}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Online
                                </Link>

                                <Link
                                    to="/tienda/cursos-presenciales"
                                    activeClassName={`nav-link active `}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Presenciales
                            </Link>*/}

                                <Link
                                    to="/tienda/fisicas"
                                    activeClassName={`nav-link active `}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Físicas
                                </Link>

                                <Link
                                    to="/tienda/psicotecnicos"
                                    activeClassName={`nav-link active `}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Psicotécnicos
                                </Link>

                                {process.env.GATSBY_ENABLE_POL_COURSES === "true" && (
                                    <Link
                                        to="/tienda/cursos-policiales"
                                        activeClassName={`nav-link active `}
                                        className={`nav-link`}
                                        partiallyActive={true}
                                    >
                                        Cursos Policiales
                                    </Link>
                                )}

                                <Link
                                    to="/tienda/certificados"
                                    activeClassName={`nav-link active`}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Certificados
                                </Link>

                                {/*<Link
                                    to="/tienda/ispc"
                                    activeClassName={`nav-link active`}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    ISPC
                                </Link>*/}

                                <Link
                                    to="/tienda/entrevistas"
                                    activeClassName={`nav-link active`}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Entrevistas
                                </Link>

                                {/*<Link
                                    to="/tienda/locales"
                                    activeClassName={`nav-link active`}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Local
                            </Link>*/}

                                <Link
                                    to="/tienda/bulevard"
                                    activeClassName={`nav-link active `}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Bulevard
                                </Link>

                                <Link
                                    to="/tienda/atlantic"
                                    activeClassName={`nav-link active `}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Atlàntic
                                </Link>

                                <Link
                                    to="/tienda/otros"
                                    activeClassName={`nav-link active`}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Otros cursos
                                </Link>

                                <a
                                    href="https://cursos.iopos.es"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className={`nav-link hidden lg:inline`}
                                >
                                    Campus
                                </a>

                                <Link
                                    to="/rankings"
                                    activeClassName={`nav-link active`}
                                    className={`nav-link`}
                                    partiallyActive={true}
                                >
                                    Ránkings
                                </Link>

                                {itemsCount > 0 && (
                                    <Link
                                        to="/cesta"
                                        activeClassName="active"
                                        className="nav-link-alt items-center"
                                        title={`Cesta (${itemsCount})`}
                                    >
                                        <MdShoppingCart
                                            title="Cesta de la compra"
                                            className="w-4 h-4 inline-block items-center fill-current"
                                            style={{ verticalAlign: "middle" }}
                                        />

                                        <div className="badge">{itemsCount}</div>
                                    </Link>
                                )}
                            </nav>
                        </div>
                    </div>
                </div>

                {/** Mobile menú */}
                <div className="absolute w-full mt-0 shadow-md mobile-background">
                    <div className="container">
                        <nav
                            className={`lg:hidden duration-300 overflow-hidden ${
                                mobileMenu ? "h-auto" : "h-0"
                            }`}
                        >
                            <ul className="pt-3 opacity-85">
                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/"
                                        activeClassName="nav-link-mobile active"
                                        className={`nav-link-mobile`}
                                    >
                                        Inicio
                                    </Link>
                                </li>

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/nosotros/"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                    >
                                        Nosotros
                                    </Link>
                                </li>

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/calculadora-iopos"
                                        activeClassName="nav-link-mobile active"
                                        className={`nav-link-mobile`}
                                    >
                                        Calculadora
                                    </Link>
                                </li>

                                {process.env.GATSBY_BLOG_ENABLED === "true" && (
                                    <li className="border-b py-3 border-5">
                                        <Link
                                            to="/blog"
                                            activeClassName="nav-link-mobile active"
                                            className={`nav-link-mobile`}
                                            partiallyActive={true}
                                        >
                                            Blog
                                        </Link>
                                    </li>
                                )}

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/convocatorias"
                                        activeClassName="nav-link-mobile active"
                                        className={`nav-link-mobile`}
                                    >
                                        Convocatorias
                                    </Link>
                                </li>

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/oposiciones"
                                        activeClassName="nav-link-mobile active"
                                        className={`nav-link-mobile`}
                                    >
                                        Oposiciones
                                    </Link>
                                </li>

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/aplicaciones/"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Aplicaciones
                                    </Link>
                                </li>

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/rankings"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Ránkings
                                    </Link>
                                </li>

                                {/*<li className="border-b py-3 border-5">
                                    <Link
                                        to="/aplicaciones/"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Aplicaciones
                                    </Link>
                                </li>*/}

                                {/*<li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/cursos-online"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Cursos Online
                                    </Link>
                                </li>*/}

                                {/*<li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/cursos-presenciales"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Cursos Presenciales
                                    </Link>
                                </li>*/}

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/libros"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Libros
                                    </Link>
                                </li>

                                {/*<li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/audios"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Podcasts
                                    </Link>
                                </li>*/}

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/fisicas"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Físicas
                                    </Link>
                                </li>

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/psicotecnicos"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Psicotécnicos
                                    </Link>
                                </li>

                                <li className="border-b py-3 border-5">
                                    <a
                                        href="https://cursos.iopos.es"
                                        rel="noreferrer noopener"
                                        target="_blank"
                                        className="nav-link-mobile"
                                    >
                                        Campus
                                    </a>
                                </li>

                                {process.env.GATSBY_ENABLE_POL_COURSES === "true" && (
                                    <Link
                                        to="/tienda/cursos-policiales"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Cursos Policiales
                                    </Link>
                                )}

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/certificados"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Certificados
                                    </Link>
                                </li>

                                {/*<li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/ispc"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Cursos ISPC
                                    </Link>
                                </li>*/}

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/entrevistas"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Entrevistas
                                    </Link>
                                </li>

                                {/*<li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/locales"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Locales
                                    </Link>
                                </li>*/}

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/bulevard"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Bulevard
                                    </Link>
                                </li>

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/atlantic"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                        partiallyActive={true}
                                    >
                                        Atlàntic
                                    </Link>
                                </li>

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/tienda/otros"
                                        activeClassName={`nav-link active`}
                                        className={`nav-link`}
                                        partiallyActive={true}
                                    >
                                        Otros cursos
                                    </Link>
                                </li>

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/rankings/"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                    >
                                        Ránkings
                                    </Link>
                                </li>

                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/preguntas-frecuentes/"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                    >
                                        Faqs
                                    </Link>
                                </li>
                                <li className="border-b py-3 border-5">
                                    <Link
                                        to="/contacto/"
                                        activeClassName="nav-link-mobile active"
                                        className="nav-link-mobile"
                                    >
                                        Contacto
                                    </Link>
                                </li>
                                {itemsCount > 0 && (
                                    <li className="py-3">
                                        <Link
                                            to="/cesta"
                                            activeClassName="nav-link-mobile active"
                                            className={`nav-link-mobile`}
                                            title={`Cesta (${itemsCount})`}
                                        >
                                            Cesta
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Header;
